import React from "react"
import Layout from "@components/layout"
import Div from "@components/elements/div"
import Anchor from "@components/elements/anchor"
import Heading from "@components/elements/heading"
import List from "@components/elements/list"
import Paragraph from "@components/elements/paragraph"
import { KlipsEOPCta } from "@components/cta/eop/klips"
import GalleryHeader from "@components/klips/gallery-header"
import Flex from "@components/elements/flex"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

const SolutionsPage = ({ data }) => {
  const solutions = data.solutions.nodes

  return (
    <Layout
      title="Dashboard Solutions from Klipfolio"
      titleExtension=""
      description={`Explore these examples and use cases to see how Klipfolio can benefit your team.`}
      fullWidth
    >
      <GalleryHeader
        name={"By use case"}
        subtitle={
          "Explore all the different ways you can use Klipfolio to gain better data insights."
        }
        cta="Start a free trial"
        links={[
          {
            text: "Solutions",
          },
        ]}
      />
      <Div textContainer margin="0 auto 6rem">
        <Flex gap="1rem" margin="0 0 2rem">
          <Heading as="h2">
            Looking for a solution to your business analytics and monitoring
            problems? We have it!
          </Heading>
          <Paragraph>
            Since 2001, Klipfolio has been helping businesses and team succeed
            with data. No matter the complexity or challenge we are here to help
            you gain the business advantage you need by enabling the data
            insights you need to drive better data driven decisions.
          </Paragraph>
          <Paragraph>
            Here is a small list of common apps, use cases, departments and
            industries to give you a glimsp of what is possible with
            Klipfolio&apos;s platform.
          </Paragraph>
        </Flex>
        <Heading as="h2" margin="0 0 1rem">
          Use Cases
        </Heading>
        <List gap="0">
          {solutions.map(solution => (
            <li key={solution.slug}>
              <Anchor link={`/solutions/${solution.slug}`}>
                {solution.name}
              </Anchor>
            </li>
          ))}
        </List>
        <Flex gap="1rem">
          <Heading as="h2">Common app integrations</Heading>
          <List gap="0">
            <li>
              <Anchor link="/integrations/excel-dashboard">
                Excel Dashboard
              </Anchor>
            </li>
            <li>
              <Anchor link="/integrations/google-analytics-dashboard">
                Google Analytics Reports
              </Anchor>
            </li>
            <li>
              <Anchor link="/integrations/salesforce-dashboard">
                Salesforce Dashboard
              </Anchor>
            </li>
            <li>
              <Anchor link="/integrations/mysql-dashboard">
                SQL Reporting Software
              </Anchor>
            </li>
            <li>
              <Anchor link="/integrations">All Integrations</Anchor>
            </li>
          </List>
        </Flex>
        <Heading as="h2" margin="0 0 1rem">
          Social Media Analytics Reporting
        </Heading>
        <List gap="0">
          <li>
            <Anchor link="/integrations/facebook-dashboard">
              Facebook Analytics
            </Anchor>
          </li>
          <li>
            <Anchor link="/integrations/instagram-dashboard">
              Instagram Analytics
            </Anchor>
          </li>
          <li>
            <Anchor link="/integrations/linkedin-dashboard">
              LinkedIn Analytics
            </Anchor>
          </li>
          <li>
            <Anchor link="/integrations/twitter-dashboard">X Analytics</Anchor>
          </li>
          <li>
            <Anchor link="/integrations/youtube-dashboard">
              YouTube Analytics
            </Anchor>
          </li>
        </List>
        <Heading as="h2" margin="0 0 1rem">
          Advertising Analytics & Reporting
        </Heading>
        <List gap="0">
          <li>
            <Anchor link="/integrations/bingads-dashboard">Bing Ads</Anchor>
          </li>
          <li>
            <Anchor link="/integrations/googleadwords-dashboard">
              Google Ads
            </Anchor>
          </li>
          <li>
            <Anchor link="/integrations/facebook-ads-dashboard">
              Facebook Ads
            </Anchor>
          </li>
          <li>
            <Anchor link="/integrations/instagram-dashboard">
              Instagram Ads
            </Anchor>
          </li>
          <li>
            <Anchor link="/integrations/linkedin-ads-dashboard">
              LinkedIn Ads
            </Anchor>
          </li>
          <li>
            <Anchor link="/integrations/twitter-ads-dashboard">
              Twitter Ads
            </Anchor>
          </li>
          <li>
            <Anchor link="/integrations/youtube-dashboard">YouTube Ads</Anchor>
          </li>
        </List>
        <Heading as="h2" margin="0 0 1rem">
          Additional Resources
        </Heading>
        <List gap="0">
          <li>
            <Anchor link="/blog/what-is-a-tv-dashboard">
              What is a TV Dashboard?
            </Anchor>
          </li>
          <li>
            <Anchor link="/resources/articles/self-service-business-intelligence">
              What is self-service BI?
            </Anchor>
          </li>
          <li>
            <Anchor link="/resources/articles/business-intelligence-dashboards">
              What are business intelligence dashboards?
            </Anchor>
          </li>
          <li>
            <Anchor link="/resources/articles/kpi-report-building">
              What is KPI report building?
            </Anchor>
          </li>
          <li>
            <Anchor link="/blog/starter-guide-to-dashboard-design">
              How to build a mobile BI dashboard?
            </Anchor>
          </li>
          <li>
            <Anchor link="/blog/starter-guide-to-dashboard-design">
              How to design a dashboard?
            </Anchor>
          </li>
          <li>
            <Anchor link="/blog/starter-guide-to-dashboards">
              How to choose the right type of dashboard?
            </Anchor>
          </li>
          <li>
            <Anchor link="/resources/dashboard-examples">
              Dashboard examples
            </Anchor>
          </li>
        </List>
      </Div>
      <KlipsEOPCta />
    </Layout>
  )
}

SolutionsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SolutionsPage

export const pageQuery = graphql`
  query SolutionsQuery {
    solutions: allKlipsSolutions {
      nodes {
        name
        slug
      }
    }
  }
`
